<template>
  <section>
    <v-row
      align="start"
      justify-md="space-between"
      justify="center"
      class="py-4 py-sm-8"
    >
      <v-col md="4" order-md="12" order="12" cols="12">
        <v-row no-gutters justify="center" justify-md="end">
          <v-col cols="12" md="auto">
            <awesometechstack-img style="opacity: 0.97" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="8">
        <div class="d-inline-flex align-center">
          <app-icon-pulse :size="32" class="mr-4"></app-icon-pulse>
          <span class="text-overline">
            {{ $t('SectionsIndexWebsiteAnalyzer.title') }}
          </span>
        </div>

        <h2 class="mb-4">
          {{ $t('SectionsIndexWebsiteAnalyzer.title2') }}
        </h2>
        <p class="text-medium-emphasis">
          {{ $t('SectionsIndexWebsiteAnalyzer.description') }}
        </p>
        <div class="mt-7">
          <analyze-bar
            theme="light"
            style="max-width: 570px"
            single-line
            variant="solo"
          />
        </div>

        <div class="text-overline pt-4">
          {{ $t('SectionsIndexWebsiteAnalyzer.title3') }}
        </div>
        <app-highlight v-for="i in 3" :key="i">
          {{ $t(`SectionsIndexWebsiteAnalyzer.highlights[${i}]`) }}
        </app-highlight>
      </v-col>
    </v-row>
  </section>
</template>
